html {
  -webkit-font-smoothing: 'antialiased';
  -moz-osx-font-smoothing: 'grayscale';
  box-sizing: 'border-box';
  font-size: 100% !important;
}

*,
*::before,
*::after {
  box-sizing: 'inherit';
}

strong,
b {
  font-weight: 'bolder';
}

body {
  font-family: Ciutadella-Regular, sans-serif !important;
  margin: 0;
  background-color: #ffffff;
  z-index: 1;
  position: relative;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004) !important;
}

@media (min-width: 768px) {
  body {
    overflow-x: hidden;
  }
}

button:focus {
  outline: 0;
}

#app {
  width: 100%;
}
